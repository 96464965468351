<script lang="ts" setup>
import type { BOffcanvas, BOffcanvasProps } from 'bootstrap-vue-next'
import { shallowRef } from 'vue'

withDefaults(defineProps<BOffcanvasProps>(), {
  bodyScrolling: true,
  hideBackdrop: true,
})

const offCanvas = shallowRef<InstanceType<typeof BOffcanvas>>()
const show = () => offCanvas.value?.show()
const hide = () => offCanvas.value?.hide()
const toggle = () => {
  show()
}

defineExpose({
  show,
  hide,
  toggle,
})
</script>

<template>
  <b-offcanvas
    v-bind="{ ...$props, ...$attrs }"
    ref="offCanvas"
  >
    <template
      v-for="(_, name) in $slots"
      #[name]="scope"
    >
      <slot
        :name
        v-bind="scope ?? {}"
      />
    </template>
  </b-offcanvas>
</template>
